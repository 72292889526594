import $axios from '@/utils/request'

// 获取试卷列表
export function getExamList (data) {
  const url = '/exam/list'
  return $axios.fPost(url, data)
}

// 新增试卷
export function addExam (data) {
  const url = '/exam/add'
  return $axios.fPost(url, data)
}

// 编辑试卷
export function editExam (data) {
  const url = '/exam/edit'
  return $axios.fPost(url, data)
}

// 删除试卷
export function delExam (data) {
  const url = '/exam/del'
  return $axios.fPost(url, data)
}

// 获取试卷详细信息
export function getExamInfo (data) {
  const url = '/exam/getInfo'
  return $axios.fPost(url, data)
}

// 获取学员考试-试卷详细信息
export function getUserExam (data) {
  const url = '/exam/getUserExam'
  return $axios.fPost(url, data)
}

// 批阅试卷
export function examScore (data) {
  const url = '/exam/score'
  return $axios.fPost(url, data)
}

// 批阅试题
export function questionScore (data) {
  const url = '/question/score'
  return $axios.fPost(url, data)
}

// 获取试卷使用次数
export function getUsedTimes (data) {
  const url = '/exam/getUsedTimes'
  return $axios.fGet(url, data)
}