<template>
  <div class="look-box" v-loading="loading">
    <iframe id='scratchjriframe' class="iframess" :src="iframeWinPaht" frameborder="0" ref="iframes" />
  </div>
</template>
<script>
import { getWorkInfo } from "@/api/works/index";
import { getSrcTplInfo } from "@/api/research/scratch";

export default {
  name: "ScratchJrLook",
  props:{
    taskInfo: {
      type: Object,
      default:()=>({})
    }
  },
  data() {
    return {
      iframeWin: {},
      loading: false,
      iframeWinPaht: process.env.VUE_APP_PREVIEW_SCRATCHJR +"/editor.html?pmd5=" + uuid() + "&mode=edit",
      // iframeWinPaht: process.env.VUE_APP_SCRATCHJR_IDE + "edit?pmd5=1&mode=edit",
    };
  },
  mounted() {
    this.iframeWin = document.getElementById('scratchjriframe').contentWindow;
    window.addEventListener("message", this.handleMessage, true);
    this.loading = true;
    setTimeout(() => {
      // 携带id 进入查看编辑模式 不带进入创建
      let scratchJrDom = document.getElementById('scratchjriframe').contentWindow;
      scratchJrDom.postMessage(
        { type: "initData", data: { id: this.$route.query.id || this.taskInfo.id } },
        "*"
      );
    }, 2000);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleMessage, true);
  },
  methods: {
    handleMessage(e) {
      switch (e.data.type) {
        case "newProjectFinish":
          if (this.$route.query.id || this.taskInfo.sourceId) {
            setTimeout(() => {
              if(this.$route.query.id || this.taskInfo.sourceId){
                this.getWorkInfo(this.$route.query.id || this.taskInfo.sourceId);
              } else if(this.taskInfo.id){
                if(this.taskInfo.sourceContent){
                  let scratchJrDom = document.getElementById('scratchjriframe').contentWindow;
                  scratchJrDom.postMessage({
                    type: "loadProjectSjr",
                    data: { url: this.taskInfo.sourceContent },
                  },"*");
                  this.loading = false;
                } else {
                  this.loading = false;
                }
              }
            }, 1000);
          } else {
            this.loading = false;
          }
          break;
        case "loadProjectFinish":
          this.loading = false;
          break;
        default:
          break;
      }
    },
    // 初次获取详情信息
    getWorkInfo(id) {
      this.loading = true;
      getWorkInfo({ id })
        .then((res) => {
          if (res.body.sourceCodeFile) {
            let scratchJrDom = document.getElementById('scratchjriframe').contentWindow;
            scratchJrDom.postMessage(
              {
                type: "loadProjectSjr",
                data: { url: res.body.sourceCodeFile },
              },
              "*"
            );
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped lang='scss'>
.look-box,
.iframess {
  width: calc(100vw);
  height: calc(100vh);
  overflow: hidden;
}
</style>