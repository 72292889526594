import $axios from '@/utils/request'

// 获取题库列表
export function getQuesibraryList (data) {
  const url = '/questionlibrary/getQuestionList'
  return $axios.fPost(url, data)
}

// 新增题库
export function addQuestionLibrary (data) {
  const url = '/questionlibrary/addQuestionLibrary'
  return $axios.fPost(url, data)
}

// 编辑题库
export function updateQuestionLibrary (data) {
  const url = '/questionlibrary/updateQuestionLibrary'
  return $axios.fPost(url, data)
}

// 启/停/删除 问题
export function enableQues (data) {
  const url = '/questionlibrary/updateQuestionState'
  return $axios.fPost(url, data)
}


// 查询题库的题目
export function getQuestionByLibraryId (data) {
  const url = '/questionlibrary/getQuestionByLibraryId'
  return $axios.fGet(url, data)
}

// 题库题目排序
export function sortQuestionLibrary (data) {
  const url = '/questionlibrary/sortQuestionLibrary'
  return $axios.fPost(url, data)
}

// 获取题库集
export function getQuestionList (data) {
  const url = '/teacher/questionlibrary/getQuestionList'
  return $axios.fGet(url, data)
}

// 修改学生开放
export function updateQuestionState (data) {
  const url = '/teacher/questionlibrary/updateQuestionState'
  return $axios.fPost(url, data)
}

// 修改学生开放题解视频
export function updateProblemUser (data) {
  const url = '/teacher/questionlibrary/updateProblemUser'
  return $axios.fPost(url, data)
}
// 获取学生统计
export function getStudentSolutionStatistics (data) {
  const url = '/teacher/questionlibrary/getStudentSolutionStatistics'
  return $axios.fGet(url, data)
}

// 获取学生的提交记录
export function getSubmitHistory (data) {
  const url = '/teacher/questionlibrary/getSubmitHistory'
  return $axios.fPost(url, data)
}

//
export function previewGetQuestionList (data) {
  const url = '/preview/question/getQuestionList'
  return $axios.fGet(url, data)
}

//
export function submitSolutionCode (data) {
  const url = '/preview/question/submitSolutionCode'
  return $axios.fPost(url, data)
}

//
export function getSubmitHistoryItem (data) {
  const url = '/preview/question/getSubmitHistoryItem'
  return $axios.fGet(url, data)
}

//
export function getQuestionById (data) {
  const url = '/preview/question/getQuestionById'
  return $axios.fGet(url, data)
}

//
export function getLessonIdByContest (data) {
  const url = '/preview/question/getLessonIdByContest'
  return $axios.fGet(url, data)
}

// 获取最后一次提交代码
export function getLastSubmit (data) {
  const url = '/preview/question/getLastSubmit'
  return $axios.fGet(url, data)
}

// 我的提交记录
export function mySubmitHistory (data) {
  const url = '/preview/question/getSubmitHistory'
  return $axios.fGet(url, data)
}

// 查询题库详情
export function getContextInfo (data) {
  const url = '/questionlibrary/getContextInfo'
  return $axios.fGet(url, data)
}
